<template>
  <div class="notifications-tab notifications-tab-all">
    <!-- Loader -->
    <template v-if="loading && !items.meta">
      <spinner large center style="height: 70vh" />
    </template>

    <template v-else-if="items.data.length">
      <NotificationItem
        v-for="notification in items.data"
        :key="notification.id"
        :notification="notification"
        class="py-2 my-0 border-radius-top-first border-radius-bottom-last"
      />

      <!-- Load more -->
      <div v-if="$utils.canLoadMore(items.meta)" class="text-center mt-5">
        <v-btn
          color="primary"
          rounded
          :loading="loading && !!items.meta"
          small
          @click="fetch(true)"
        >
          {{ $t("common.loadMore") }}
        </v-btn>
      </div>
    </template>

    <!-- Empty -->
    <v-alert v-else type="info" text class="mt-3">
      {{
        state === "all"
          ? $t("notifications.noNotifications")
          : $t("notifications.noUnread")
      }}
    </v-alert>
  </div>
</template>

<script>
import NotificationItem from "@/components/app/notifications/NotificationItem.vue";

export default {
  components: { NotificationItem },

  props: {
    state: String,
    action: String,
  },

  computed: {
    items() {
      return this.$store.getters[`notifications/${this.state}`];
    },

    loading() {
      return this.$loading(`notifications/${this.action}`);
    },
  },

  created() {
    this.fetch();
  },

  methods: {
    fetch(loadMore = false) {
      this.$store.dispatch(`notifications/${this.action}`, loadMore);
    },
  },
};
</script>

<style></style>
