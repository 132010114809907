<template>
  <column-layout class="page" sticky-sides noRight>
    <!-- Left -->
    <template v-slot:left>
      <Menu />
    </template>

    <!-- Main -->
    <template v-slot>
      <div class="widget">
        <label class="label"> {{ $t("common.notifications") }} </label>

        <div class="card pa">
          <!-- Tabs -->
          <div class="tab-buttons flex-column flex-md-row">
            <v-btn
              class="notification__tab_button px-8 tab-button"
              :color="tab === 0 ? 'primary' : 'secondary'"
              @click="tab = 0"
            >
              {{ $t("notifications.all") }}
            </v-btn>

            <v-btn
              class="notification__tab_button px-8 tab-button"
              :color="tab === 1 ? 'primary' : 'secondary'"
              @click="tab = 1"
            >
              {{ $t("notifications.unread") }} ({{ unreadCount }})
            </v-btn>

            <v-spacer />

            <!-- Mark all as read -->
            <v-btn
              v-if="$store.getters['notifications/hasUnread']"
              rounded
              style="height: 48px"
              color="secondary"
              :loading="$loading('notifications/markAllAsRead')"
              @click="markAllAsRead"
            >
              {{ $t("notifications.markAllAsRead") }}
            </v-btn>
          </div>

          <!-- Tab items -->
          <v-tabs-items v-model="tab" class="mt-3">
            <!-- All -->
            <v-tab-item>
              <NotificationsFeed state="all" action="fetchAll"
            /></v-tab-item>

            <!-- Unread -->
            <v-tab-item>
              <NotificationsFeed state="unread" action="fetchUnread" />
            </v-tab-item>
          </v-tabs-items>
        </div>
      </div>
    </template>
  </column-layout>
</template>

<script>
import Menu from "@/components/app/Menu.vue";
import NotificationsFeed from "@/components/app/notifications/NotificationsFeed.vue";

export default {
  metaInfo: {
    title: "Notifications",
  },

  components: {
    Menu,
    NotificationsFeed,
  },

  data: () => ({
    tab: 0,
  }),

  computed: {
    unreadCount() {
      const unread = this.$store.getters["notifications/unread"];
      return unread.meta ? unread.meta.total : 0;
    },
  },

  methods: {
    markAllAsRead() {
      this.$store.dispatch("notifications/markAllAsRead");
    },
  },
};
</script>

<style lang="scss" scoped>
.notification__tab_button {
  border-radius: 30px !important;
  border: none !important;
}

.v-item--active {
  background-color: var(--v-primary-base) !important;
}
</style>
